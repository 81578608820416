// Modal
.modal-content {
	background: $primary !important;
	color: white;
	font-size: 1.25rem;
	font-weight: 300;
	text-align: center;
	border-radius: 25px !important;
}

.modal-header {
	border-bottom: 0 !important;
}

.modal-body {
	padding: 3rem !important;
}