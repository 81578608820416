// Global
body {
	width: 100%;
	overflow-x: hidden;
	position: relative;
	padding-top: 110px;

	img {
		max-width: 100%;
		height: auto;
	}
}