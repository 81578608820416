// Buttons
.btn {
	color: white !important;
	margin: 1rem 0;
	font-weight: 500 !important;

	&-sm {
		padding: 0.5rem 0.75rem !important;
	}

	&-white {
		background-color: white !important;
		color: $primary !important;
	}

	&:hover {
		background-color: $secondary !important;
		color: white !important;
	}
}