// Contact section
.contact-section {

}

.contact-block {
	font-weight: 300;
	padding-left: 4rem;
	padding-right: 4rem;

	@media (max-width: 991px) {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}