// Brand Colours
$primary: #ff914d;
$secondary: #FF680A;
$success: #E05600;
// $info: purple;
// $warning: red;

// Black and Greyscale
$gray-100: #636363;
$gray-200: #d2d2d2;
$gray-300: #fbfafa;
$gray-400: #e5e8eb;
$gray-500: #7a7a7a;
$white: #fff;

$body-bg: white;
$body-color: black;

$border-radius: 50px;

// Grid
$grid-columns:	24;

// Containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1366px
) !default;

// Fonts
$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-monospace:  '';
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 1.25rem; // 16px
$font-weight-base: 300;

$h1-font-size: 2.75rem; // 44px
$h2-font-size: 2.25rem; // 36px
$h3-font-size: 1.5rem; // 24px
$h4-font-size: 1.25rem; // 20px

$headings-margin-bottom: 1rem;

// Nav
$nav-link-padding-y: 1.5rem;
$nav-link-padding-x: 0;
$navbar-brand-padding-y: 0;

// Navbar
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1.5rem;

// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 1.5rem;

$btn-border-radius:           25px;
$btn-border-radius-lg:        25px;
$btn-border-radius-sm:        25px;

$btn-font-size: 1.115rem;

// Modal
$modal-md: 720px;

// Bootstrap and its default variables
@import "../../../../node_modules/bootstrap/scss/bootstrap";