// Nav
nav {
	background-color: white;

  @media (max-width: 991px) {
    text-align: center;
  }

  &:before {
    content: "";
    background-color: white;
    height: 100%;
    width: 50%;
    position: absolute;
    left: -50%;
    top: 0;
  }

  &:after {
    content: "";
    background-color: white;
    height: 100%;
    width: 50%;
    position: absolute;
    right: -50%;
    top: 0;
  }
}

.nav-link {
	color: black !important;
  font-size: 1rem;
  font-weight: 400;
	position: relative;

	&:hover {
		color: $primary !important;
	}

  &.active {
    color: $primary !important;
  }
}

.nav-link:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: $primary;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.nav-link:hover:after { 
  width: 100%; 
  left: 0; 
}

.nav-link.active:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  width: 100%; 
  left: 0; 
  position: absolute;
  background: $primary;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}