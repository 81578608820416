// Portfolio section
#portfolio {
	border-top: 2px solid $primary;
}

.portfolio-item {
	margin: 1.5rem 0;
	height: 250px;
	border-radius: 25px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;

	&__details {
		color: white;
		font-size: 1.25rem;
		opacity: 0;
		padding: 1rem;
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		p,
		.btn {
			z-index: 2;
			position: relative;
		}
	}

	&:hover {
		.portfolio-item__details {
			opacity: 1;
			animation: fadeIn ease .5s;
			padding: 3rem;
			text-align: center;

			&:before {
				content: "";
				background: $primary;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: 0;
				animation: fadeIn ease .5s;
			}
		}
	}
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}