// Skills section
.skills-section {
	// font-size: 0.875rem;
}

.skills-carousel {
	margin-top: 3rem;

	&__skill {
		padding-left: 4rem;
		padding-right: 4rem;

		&__icon {
			margin: 0 auto 1rem;
		}
	}
}