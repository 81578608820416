// Services section
.services-section {
	// font-size: 0.875rem;
}

.service-block {
	padding-left: 4rem !important;
	padding-right: 4rem !important;
	font-weight: 300;

	@media (max-width: 991px) {
		margin-bottom: 3rem;
	}

	&__icon {
		margin: 0 auto 1rem;
	}
}