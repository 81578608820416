// Carousel
.carousel-hero {
	height: 100%;

	&__slide {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&__caption {
			font-size: 1.25em; // 20px
			padding: 3rem;
			margin: 0 auto;
			text-align: center;
			border-radius: 25px;

			p {
				margin-bottom: 0;
			}
		}
	}
}

.slick-list,
.slick-track {
	height: 100%;
}

.slick-next,
.slick-prev {
	width: 50px;
	height: 50px;
}

.slick-next {
	right: 0;
}

.slick-prev {
	left: 0;
	z-index: 1;
}